/**
 * @About
 * This file is entry point of config
 */

import {
  devSetting,
  localhostSetting,
  prodSetting,
  stagSetting,
} from "./Setting"

import _ from "lodash"

let defaultSetting = {
  api: {
    url: "https://devapi.healtyplanet.tk/apidocs/v1/ ",
  },
  lat: null,
  long: null,
  google_map_key: null,
}

let siteSetting = defaultSetting

switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, prodSetting)
    break
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, prodSetting)
    break
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, prodSetting)
    break
  default:
    siteSetting = _.extend(defaultSetting, prodSetting)
}
export default siteSetting
